import * as React from 'react';
import PropTypes from 'prop-types';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

const Head = ({ location, params, data, pageContext }) => {
  const sitedata = useSiteMetadata();
  const seo = pageContext.seo || { title: '', description: '' };
  const metaTags = {
    'description': sitedata.description || '',
    'og:title': seo?.opengraphTitle || sitedata.title,
    'og:description': seo?.opengraphDescription || sitedata.description,
    'twitter:card': 'summary',
    'twitter:creator': '',
    'twitter:title': seo?.twitterTitle || sitedata.title,
    'twitter:description': seo?.twittterDescription || sitedata.description,
  };
  // console.log(sitedata.title);
  return (
    <>
      <title>{sitedata.title}</title>
      {Object.keys(metaTags).map((key) => {
        return <meta name={key} content={metaTags[key]} key={key} />;
      })}
    </>
  );
};

Head.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      description: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  pageContext: PropTypes.shape({
    title: PropTypes.string,
    seo: PropTypes.object,
  }),
  params: PropTypes.any,
};

export default Head;
