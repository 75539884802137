import { graphql, useStaticQuery } from 'gatsby';

export const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          twitterAccount
          facebookImg
          twitterImg
          image
          siteUrl
        }
      }
      allMdx {
        nodes {
          frontmatter {
            title
          }
        }
      }
    }
  `);

  return data.site.siteMetadata;
};
